// import { isNoDemoDomain } from '../urls.ts'

export const onboardingSteps = [
  {
    title: 'Привет',
    question: 'onboarding.hello',
    step: 'hello',
  },
  {
    title: 'Ваш пол',
    question: 'onboarding.sex',
    step: 'sex',
    video: true,
  },
  {
    title: 'Ваш возраст',
    question: 'onboarding.age',
    step: 'age',
  },
  {
    title: 'Ваша профессия',
    question: 'onboarding.profession',
    step: 'profession',
  },
  {
    title: 'Ваши интересы',
    question: `onboarding.interests`,
    step: 'interests',
  },
  {
    title: 'Уровень английского',
    question: 'onboarding.level',
    step: 'level',
  },
  {
    title: 'Ваша цель',
    question: 'onboarding.goal',
    step: 'goal',
  },
  {
    title: 'Ваша цель',
    question: 'onboarding.goal_answer',
    step: 'goal_answer',
  },
  {
    title: 'Ваш приоритет',
    question: 'onboarding.improve',
    step: 'improves',
  },
  {
    title: 'Ваш темп',
    question: 'onboarding.duration',
    step: 'duration',
  },
  {
    title: 'Ваше имя',
    // question: isNoDemoDomain ? 'onboarding.name' : 'onboarding.nameWithDemo',
    question: 'onboarding.name',

    step: 'name',
  },
  //   ...(isNoDemoDomain
  //     ? []
  //     : [
  //         {
  //           title: 'Давай потренируемся!',
  //           question: 'onboarding.askDemo',
  //           step: 'askdemo',
  //         } as const,
  //       ]),
  {
    title: '',
    question: 'onboarding.program',
    step: 'generation' as const,
  },
  {
    title: '',
    question: 'onboarding.program',
    step: 'program',
  },
] as const
export type OnboardingStep = (typeof onboardingSteps)[number]['step']

export type LangLevels = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2'
