import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, IconName } from '../../shared/ui/icon/icon.tsx'
import { cn } from '../../shared/lib/utils.ts'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { enUS, ru } from 'date-fns/locale'
import { add, format } from 'date-fns'
import {
  FAQ_LIST,
  REVIEWS_LIST,
  TrialInfoBlock,
} from '../../shared/data/subscriptions.ts'
import { motion } from 'framer-motion'
import { StarIcon } from '../../shared/ui/icon/starIcons.tsx'
import { classed } from '@tw-classed/react'
import Checkbox from '../../shared/ui/checkbox/checkbox.tsx'
import {
  privacyPolicyUrl,
  termsUrl,
  recurrentTermsUrl,
  savingCredentialsUrl,
} from '../auth/login.tsx'

const locales = {
  en: enUS,
  ru: ru,
}

interface FaqItemProps {
  question: string
  answer: string
}

export const FaqBlock = () => {
  return (
    <div className="flex size-full items-center justify-center xl:bg-white">
      <div className="mt-40 w-full px-24 xl:mt-64 xl:max-w-[700px]">
        <h3 className="text-center text-24 font-extrabold ">
          Часто задаваемые вопросы
        </h3>
        <div className="mt-18 flex flex-col gap-24">
          {FAQ_LIST.map((faq) => (
            <FaqItem
              question={faq.question}
              answer={faq.answer}
              key={faq.question}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className="w-full cursor-pointer"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <div className="flex w-full items-center justify-between rounded-18 bg-white">
        <span className="text-start text-[20px] font-extrabold">
          <FormattedMessage id={question as AppMessageKeys} />
        </span>
        <Icon
          iconName={'arrowDown'}
          size="small"
          className={cn(
            'min-h-24 min-w-24 transition-transform duration-150',
            isOpen && '-rotate-180',
          )}
        />
      </div>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.15 }}
        className="overflow-hidden "
      >
        <div className="pt-10 font-nunito-7-semicondensed text-16 font-normal text-black">
          <FormattedMessage id={answer as AppMessageKeys} />
        </div>
      </motion.div>
      <hr className="mt-24 h-[1px] w-full bg-coolgray100" />
    </div>
  )
}

export const TRIAL_INFO_BLOCK: TrialInfoBlock[] = [
  //   {
  //     img: 'checkGreen2' as IconName,
  //     imgBg: 'bg-teal-light',
  //     titleColor: BLACK_TEXT,
  //     descColor: BLACK_TEXT,
  //     title: 'installTheApp' as AppMessageKeys,
  //     desc: 'trialInfoDesc1' as AppMessageKeys,
  //   },
  {
    img: 'lockOpen' as IconName,
    imgBg: 'bg-teal-light',
    titleColor: 'text-black',
    descColor: 'text-neutralgray650',
    title: 'today' as AppMessageKeys,
    desc: 'trialInfoDesc2' as AppMessageKeys,
  },
  {
    img: 'ring' as IconName,
    imgBg: 'bg-blue2',
    titleColor: 'text-blue2',
    descColor: 'text-blue2',
    title: 'day5' as AppMessageKeys,
    desc: 'trialInfoDesc3' as AppMessageKeys,
  },
  {
    img: 'star' as IconName,
    imgBg: 'bg-gray9',
    titleColor: 'text-black',
    descColor: 'text-neutralgray650',
    title: 'day7' as AppMessageKeys,
    desc: 'trialInfoDesc4' as AppMessageKeys,
  },
]

export const ProgressTracker = () => {
  return (
    <div className="relative mx-auto flex h-[300px] flex-col  items-start">
      <div className="absolute left-12 top-16 z-10 h-full w-16 bg-trialinfo-gradient" />
      <div className="flex h-full flex-col">
        {TRIAL_INFO_BLOCK.map((el, index) => (
          <TrackerElement key={`trial-info-${index}`} el={el} />
        ))}
      </div>
    </div>
  )
}

const TrackerElement = ({ el }: { el: TrialInfoBlock }) => {
  const intl = useIntl()
  const locale = intl.locale.split('-')[0] as 'en' | 'ru'
  const getDate = () => {
    const selectedLocale = locales[locale]
    const today = new Date()
    const futureDate = add(today, { days: 7 })

    return format(
      futureDate,
      locale === 'en' ? 'MMM dd, yyyy' : 'dd MMM yyyy',
      { locale: selectedLocale },
    )
  }
  return (
    <div className="z-20 flex grow gap-12">
      <div
        className={cn(
          'z-10 flex size-40 min-w-40 items-center justify-center rounded-full text-white',
          el.imgBg,
        )}
      >
        <Icon iconName={el.img} size="xsm" />
      </div>
      <div className="flex flex-col gap-4">
        <p
          className={cn(
            'text-22 font-bold',
            el.title === 'installTheApp' ? 'line-through' : '',
            el.titleColor,
          )}
        >
          <FormattedMessage id={el.title} />
        </p>
        <p className={cn('text-16 font-semibold', el.descColor)}>
          <FormattedMessage
            id={el.desc}
            values={
              el.desc === 'trialInfoDesc4'
                ? {
                    date: getDate(),
                  }
                : {}
            }
          />
        </p>
      </div>
    </div>
  )
}

export const ReviewsBlock = () => {
  return (
    <div className="flex size-full items-center justify-center xl:bg-white">
      <div className="mt-40 w-full px-24 xl:mt-64 xl:max-w-[700px]">
        <h3 className="text-center text-22 font-extrabold xl:text-24">
          Наши пользователи о нас
        </h3>
        <p className="text-center text-16 font-extrabold text-gray5 xl:mt-4 xl:font-nunito-7-semicondensed xl:font-normal xl:text-black">
          После каждого урока можно оставить отзыв, вот некоторые из них
        </p>
        <div className="mt-18 flex flex-col gap-10 xl:gap-16">
          {REVIEWS_LIST.map((review, i) => (
            <Review
              text={review.text}
              name={review.name}
              key={review.name}
              index={i}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const ReviewWrapper = classed.div(
  'flex min-h-[76px] w-full flex-col gap-4 rounded-18 bg-light-blue200 px-18 py-12 xl:min-h-[140px] xl:bg-[#FFF3D7]',
  {
    variants: {
      visible: {
        true: '',
        false: 'xl:hidden',
      },
    },
  },
)
const Review = ({
  name,
  text,
  index,
}: {
  name: string
  text: string
  index: number
}) => {
  return (
    <ReviewWrapper visible={index < 3}>
      <div className="flex justify-between">
        <div className="flex">
          {[...Array(5).keys()].map((_, i) => (
            <StarIcon
              key={`review-star-icon-${i}-${name}`}
              className="size-14"
            />
          ))}
        </div>
        <p className="text-12 font-bold xl:text-16 ">{name}</p>
      </div>
      <p className="text-12 font-semibold xl:text-16">{text}</p>
    </ReviewWrapper>
  )
}

export const ImgBranchesBlock = ({
  size,
  topElement,
  bottomElement,
}: {
  size: 'small' | 'large'
  topElement: React.ReactNode
  bottomElement: React.ReactNode
}) => {
  return (
    <div
      className={cn(
        'relative flex flex-col items-center justify-center',
        size === 'small' ? 'h-80 w-[140px]' : 'h-100 w-[162px]',
      )}
    >
      <img src="/images/branches.png" className="absolute size-full" />

      {topElement}
      {bottomElement}
    </div>
  )
}

export const DesktopRecurrentBlock = ({ plan }: { plan: number }) => {
  return (
    <div className="mt-18 flex flex-col gap-16 pl-16">
      <p>
        <FormattedMessage
          id="termsLogin"
          values={{
            btnText: 'Продолжить',
            termsLink: (
              <a
                target="_blank"
                rel="noreferrer"
                className="underline"
                href={termsUrl}
              >
                <FormattedMessage id="termsLinkName" />
              </a>
            ),
            recurrentLink: (
              <a
                target="_blank"
                rel="noreferrer"
                className="underline"
                href={recurrentTermsUrl}
              >
                <FormattedMessage id="recurrentTermsLinkName" />
              </a>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="recurrentTermsPaywall"
          values={{
            monthPlan: (
              <span>
                <FormattedMessage id="months" values={{ count: plan }} />
              </span>
            ),
          }}
        />
      </p>
    </div>
  )
}

export const MobileRecurrentBlock = ({
  plan,
  price,
  checkBoxes,
  handleSetCheckboxes,
}: {
  plan: string
  price: string
  checkBoxes: boolean[]
  handleSetCheckboxes: (index: number) => void
}) => {
  return (
    <>
      <div className="flex justify-start gap-8 text-gray3 ">
        <Checkbox
          className="mt-4 min-w-20"
          checked={checkBoxes[0]}
          handler={() => {
            handleSetCheckboxes(0)
          }}
        />
        <div>
          <FormattedMessage
            id="recurrentTermsCheckbox"
            values={{
              monthPlan: '12M',
              btnText: 'Продолжить',
              termsLink: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                  href={termsUrl}
                >
                  <FormattedMessage id="termsLinkNameCheckbox" />
                </a>
              ),
              subAgreement: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                  href={recurrentTermsUrl}
                >
                  <FormattedMessage id="recurrentTermsLinkName" />
                </a>
              ),
              privacyPolicy: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                  href={privacyPolicyUrl}
                >
                  <FormattedMessage id="privacyPolicy" />
                </a>
              ),
              savingCredentials: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                  href={savingCredentialsUrl}
                >
                  <FormattedMessage id="savingCredentials" />
                </a>
              ),
            }}
          />
        </div>
      </div>
      <div className="mt-16 flex justify-start gap-8 text-gray3">
        <Checkbox
          className="mt-4 min-w-20"
          checked={checkBoxes[1]}
          handler={() => {
            handleSetCheckboxes(1)
          }}
        />
        <div>
          <FormattedMessage
            id="reccurentTermsCheckbox2"
            values={{
              monthPlan: (
                <FormattedMessage
                  id={plan === '12M' ? 'everyYear' : 'everymonth'}
                />
              ),
              moneyPlan: price,
            }}
          />
        </div>
      </div>
    </>
  )
}
