import { Icon } from '../../shared/ui/icon/icon.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import { motion } from 'framer-motion'
import { cn, getDescId, getValueByKey } from '../../shared/lib/utils.ts'
import React, { useEffect, useRef, useState } from 'react'
import { SubscriptionPlan } from '../../shared/api/chatApi.ts'
import {
  // DiscountMonthsImage,
  DiscountYearImage,
  PaymentsImage,
} from '../../shared/ui/images/images.tsx'
import { useBuyStore, YaKassaPaymentWidget } from './buyStore.ts'
import { useSnapshot } from 'valtio/react'
import { RegLoginForm } from '../auth/login.tsx'
import { EdmanOnboardingAvatar, useOnboardingStore } from './OnboardingView.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppModule } from '../appContext.ts'
import { useIsMobile } from '../../shared/lib/hooks.ts'
import { ContactsView } from '../contacts/contactsView.tsx'
import { urls } from '../../shared/urls.ts'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TransparentGradient } from '../../shared/ui/gradient/TransparentGradient.tsx'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { StarIcon } from '../../shared/ui/icon/starIcons.tsx'
import {
  DesktopRecurrentBlock,
  FaqBlock,
  ImgBranchesBlock,
  MobileRecurrentBlock,
  ProgressTracker,
  ReviewsBlock,
} from './subscriptionsComponents.tsx'
import { EXP_BLOCKS } from '../../shared/data/subscriptions.ts'
import { IExpBlock } from '../../shared/data/subscriptions.ts'
import { Divider } from '../../shared/ui/divider/Divider.tsx'
import Rive from '@rive-app/react-canvas'

// function ChoosePlanTitle() {
//   return (
//     <>
//       <div className="mb-108">
//         <Rive
//           className="relative -top-20 left-[300px] -mb-24 h-[120px] w-[800px]"
//           src="/animations/soundwave_background2.riv"
//         />
//       </div>
//     </>
//   )
// }

// function CarouselBlock() {
//   const isMobile = useIsMobile()
//   const imageName = isMobile ? 'mobile' : 'desktop'
//   const images = range(4).map(
//     (i) => `/images/subscriptions/${imageName}-${i}.png`,
//   )
//   return (
//     <div
//       className={cn(
//         isMobile
//           ? 'w-full'
//           : 'relative z-10 rounded-[60px] bg-purple-light2 px-120 pb-40 pt-48 shadow-[0px_0px_24px_rgba(139,87,232,0.15)]',
//       )}
//     >
//       <div className="mx-auto mb-16 w-300 text-center text-24 font-bold sm:w-auto">
//         <FormattedMessage id="communicateNotOnlyChat" />
//       </div>
//       <Carousel.Root
//         opts={{ loop: false, dragFree: true }}
//         className={cn(!isMobile && 'w-1000')}
//       >
//         <Carousel.Content>
//           {images.map((x, index) => (
//             <Carousel.Item
//               classNameWidth={isMobile ? 'w-300' : undefined}
//               key={index}
//             >
//               <img className="mx-auto" src={x} />
//             </Carousel.Item>
//           ))}
//         </Carousel.Content>
//         {!isMobile && (
//           <>
//             <Carousel.Previous />
//             <Carousel.Next />
//           </>
//         )}
//         <Carousel.Dots className="mt-12" />
//       </Carousel.Root>
//     </div>
//   )
// }

export const SubscriptionView = () => {
  const isMobile = useIsMobile()
  const params = useParams()
  const location = useLocation()

  const componentMap = {
    paywall: <RegistrationPage />,
    // paywall: <BuyPage />,
    // 'card-added': <BuyPage />,
  }

  const content =
    getValueByKey(componentMap, params.section) ??
    (isMobile ? <SubscriptionViewMobile /> : <SubscriptionViewDesktop />)
  if (isMobile) {
    return content
  }

  return (
    <div
      className={cn(
        'h-fit min-h-dvh overflow-hidden p-16 xl:p-0 ',
        location.pathname === '/subscriptions' && 'bg-light',
      )}
    >
      <div className="mx-auto flex flex-col items-center">{content}</div>
    </div>
  )
}

function SecurityBlock() {
  return (
    <>
      <div className="mt-76 flex max-w-[500px] flex-col items-center">
        <div className="mb-16 max-w-600 text-center">
          <FormattedMessage id="subscription.security" />
        </div>
        <PaymentsImage className="max-w-[90%] sm:max-w-full" />
      </div>
    </>
  )
}

function PlansBlock() {
  return (
    <div className="group mb-24 mt-72 sm:w-max">
      <div className="relative flex flex-col items-center gap-2 sm:flex-row">
        {/* <div className="absolute right-0 top-[-92px]">
          <VideoAvatarBlock
            className="sm:mr-60"
            videoUrl={getVideoUrl(5)}
          />
        </div> */}
        <Plan level="B1" planType="1M" />
        {/* <Plan level="B1" planType="3M" /> */}
        <Plan level="B1" planType="12M" />
      </div>
    </div>
  )
}

export const planInfos = {
  '1M': {
    month: 1,
    weeks: 4,
    hours: [5, 5, 15],
    words: 150,
    price: { ru: '1990', kz: '9984' },
    discount: '',
    duration: 1,
    lessonCount: '140',
    speakListenClasses: '40',
    readWriteClasses: '80',
    lessonCountRoleplay: 3,
    period: '1 месяц',
    plan: '1M',
  },
  '3M': {
    period: '3 месяца',
    discount: '23%',
    month: 3,
    weeks: 12,
    hours: [20, 30, 30],
    words: 350,
    price: { ru: '1390', kz: '4799' },
    duration: 3,
    lessonCount: '650+',
    speakListenClasses: '250',
    readWriteClasses: '350',
    lessonCountRoleplay: 9,
    plan: '3M',
  },
  '12M': {
    period: '12 месяцев',
    month: 12,
    discount: '75%',
    weeks: 52,
    hours: [80, 100, 70],
    words: 2500,
    price: { ru: '5990', kz: '30054' },
    duration: 12,
    lessonCount: '650+',
    speakListenClasses: '250',
    readWriteClasses: '350',
    lessonCountRoleplay: 30,
    plan: '12M',
  },
} satisfies Record<SubscriptionPlan, unknown>

function Header() {
  return (
    <div className="flex flex-col items-center">
      <img src="/images/edman-logo.svg" className="w-[310px]" />

      <div className="relative mb-12 text-center text-[40px] font-extrabold leading-none">
        <FormattedMessage id="subscription.title" />
        <div className="text-blue4">
          <FormattedMessage id="subscription.subtitle" />
        </div>

        <div className="absolute right-[-240px] top-[-40px]">
          <Rive
            className="absolute left-[-10px] top-[-40px] size-[80px]"
            src="/animations/sparkle1.riv"
          />
          <Rive
            className="absolute bottom-[0px] right-[-40px] size-[80px]"
            src="/animations/sparkle2.riv"
          />
          <Rive
            className="absolute right-[-40px] top-[0px] size-[80px]"
            src="/animations/sparkle3.riv"
          />
          <EdmanOnboardingAvatar
            isSubscription={true}
            className="size-[206px] object-cover"
          />
        </div>
        {/* <img
          src="/images/edman-face.png"
          className="absolute right-[-240px] top-[-40px] w-[200px] rounded-full border-12 border-white"
        /> */}
      </div>
      <div className="mt-40 text-center font-nunito-7-semicondensed text-20 font-normal">
        <FormattedMessage id="subscription.subtitle2" />
      </div>
    </div>
  )
  //   return (
  //     <>
  //       <div className="relative mx-auto mb-16 max-w-fit text-24 font-extrabold text-black sm:text-[48px]">
  //         <Rive
  //           className="absolute left-[-100px] top-[-60px] size-[340px]"
  //           src="/animations/subscriptions_header.riv"
  //         />
  //         <div className="absolute left-[-600px] top-0 size-[630px] rounded-full bg-[#F8E8DE]/30 blur-[100px]" />
  //         <div className="absolute bottom-[-800px] right-[-400px] size-[500px] rounded-full bg-[#F8E8DE]/30 blur-[100px]" />

  //         <div className="relative max-w-800 text-center leading-[1.2em] ">
  //           <FormattedMessage id="subscription.title" />
  //           <div className="text-main-light">
  //             <FormattedMessage id="subscription.subtitle" />
  //           </div>
  //         </div>
  //       </div>
  //       <ProgramLink />
  //     </>
  //   )
}

// function ProgramLink() {
//   const authStore = useAppModule().authStore
//   const hideProgramButton = authStore.state.isAnonOrLogout
//   return (
//     <LinkButton
//       to={urls.index}
//       bg="blue"
//       className={cn(
//         'sm:mb-40',
//         hideProgramButton && 'hidden sm:invisible sm:block',
//       )}
//       rounded="full"
//     >
//       <FormattedMessage id="educationProgram" />
//     </LinkButton>
//   )
// }

export function SubscriptionContent(props: {
  planType: SubscriptionPlan
  level: string
  className?: string
}) {
  const planInfo = planInfos[props.planType]
  const levelA = props.level == 'A2'
  const intl = useIntl()
  function line(value: string, text: string) {
    return (
      <div className="text-16 font-semibold text-light sm:whitespace-nowrap">
        <span className="text-20 font-bold">{value}</span> {text}
      </div>
    )
  }
  const content = [
    line(
      intl.formatMessage({ id: 'weeks' }, { count: planInfo.weeks }),
      intl.formatMessage({ id: 'Personalized Program' }),
    ),
    line(planInfo.lessonCount, intl.formatMessage({ id: 'Thematic lesson' })),
    line(
      planInfo.speakListenClasses,
      intl.formatMessage({ id: 'speaking and listening classes' }),
    ),
    line(
      planInfo.readWriteClasses,
      intl.formatMessage({ id: 'reading and writing classes' }),
    ),
    line('', intl.formatMessage({ id: 'Error correction and practice' })),
    line(
      '',
      intl.formatMessage({ id: 'Communicating with artificial intelligence' }),
    ),
    levelA
      ? null
      : line(
          planInfo.lessonCountRoleplay.toString(),
          intl.formatMessage(
            { id: 'Role Play' },
            { count: planInfo.lessonCountRoleplay },
          ),
        ),
  ]

  return (
    <div className={props.className}>
      <div className="flex flex-col gap-8 text-16 font-semibold">
        {content.filter(Boolean).map((x, i) => (
          <div className="flex items-center gap-8" key={i}>
            <Icon
              className="shrink-0 text-green"
              iconName="checkGreen"
              size="sm"
            />{' '}
            {x}
          </div>
        ))}
      </div>

      <div className="mt-8 flex flex-row justify-between text-18 font-bold sm:flex-col sm:text-20">
        <div className="flex items-center gap-4 whitespace-nowrap">
          <span className="align-middle text-20 sm:ml-[-10px] sm:text-32">
            📖{' '}
          </span>
          +
          {intl.formatMessage({ id: 'wordsPlural' }, { count: planInfo.words })}
        </div>
        <div className="flex  items-center gap-4 whitespace-nowrap">
          <span className="ml-[-8px] align-middle text-20 sm:text-32">⏱️ </span>{' '}
          {intl.formatMessage(
            { id: 'subscription.hours' },
            { count: planInfo.hours[2] },
          )}
        </div>
      </div>
    </div>
  )
}

export function BackButtonBuy() {
  const navigate = useNavigate()

  return (
    <button
      id="onboarding.mobile-back"
      onClick={() => {
        navigate(-1)
      }}
      className="absolute size-32 sm:hidden"
    >
      <Icon iconName="left" className="text-black" />
    </button>
  )
}

export function SuccessButtonBuy(props: {
  btnText: React.ReactNode
  disableButton?: boolean
  onClick: () => void
}) {
  const authStore = useAppModule().authStore
  const state = useSnapshot(authStore.state)

  return (
    <div
      className={cn(
        'fixed inset-x-0 bottom-0 z-[10001] flex h-[52dvh] w-full flex-col bg-white px-16',
      )}
    >
      <TransparentGradient direction="top" />

      <Button
        bg="blue-gradient"
        id="subscriptions-reg-button"
        className="mb-16 mt-auto flex w-full justify-center"
        size="large"
        rounded="full"
        disabled={state.loading || props.disableButton}
        data-testid="reg-btn"
        onClick={props.onClick}
      >
        {props.btnText}
      </Button>
    </div>
  )
}

export function BuyHeader(props: {
  title: React.ReactNode
  withBackBtn: boolean
}) {
  return (
    <div className="fixed left-0 top-0 z-10 flex w-full bg-white px-16 pb-24 pt-8">
      {props.withBackBtn && <BackButtonBuy />}
      <div className="w-full text-center text-18 font-bold">{props.title}</div>
    </div>
  )
}
// @TODO - Deprecate Buy Page
//
// export function BuyPage() {
//   const buyStore = useBuyStore()
//   const authStore = useAppModule().authStore
//   const state = useSnapshot(buyStore.state)
//   const navigate = useNavigate()
//   const location = useLocation()
//   const [isSuccess, setIsSuccess] = useState(false)
//   const [isLoading, setIsLoading] = useState(false)

//   useEffect(() => {
//     if (location.pathname === '/subscriptions/card-added') {
//       setIsSuccess(true)
//       return
//     }
//     setIsLoading(true)
//     const initHandle = () => {
//       buyStore.setPlan(state.plan)
//       buyStore.setTrial(true)
//       authStore.loadUser()
//     }

//     const successHandle = () => {
//       setIsSuccess(true)

//       if (buyStore.state.provider === 'cp') {
//         navigate(urls.subscriptions('card-added'))
//       }
//     }

//     const executeBuy = async () => {
//       initHandle()
//       const widgetConstructor = await buyStore.getBuyConstructor()

//       if (buyStore.state.provider === 'cp') {
//         const cloudPaymentsWidget = widgetConstructor as {
//           mount: (element: HTMLElement) => void
//           on: (
//             eventName: string,
//             callback: (result: React.ReactNode) => void,
//           ) => void
//           destroy: () => void
//         }
//         const paymentElement = document.getElementById('cloudpayments')
//         if (paymentElement) {
//           setIsLoading(false)
//           cloudPaymentsWidget.mount(paymentElement)
//           cloudPaymentsWidget.on('success', () => {
//             successHandle()
//           })
//           cloudPaymentsWidget.on('fail', () => {
//             // failHandle()
//             setIsSuccess(false)
//           })
//         }
//         return () => {
//           cloudPaymentsWidget.destroy()
//           setIsLoading(false)
//         }
//       } else {
//         setIsLoading(false)
//         const yooKassaWidget = widgetConstructor as YaKassaPaymentWidget
//         yooKassaWidget.on('complete', () => {
//           const url = buyStore.getSuccessURL()
//           window.location.href = url
//           yooKassaWidget.destroy()
//         })
//         yooKassaWidget.render('yakassa')
//       }
//     }
//     void executeBuy()
//   }, [authStore, buyStore, navigate, state.plan, location.pathname])

//   const handleClick = () => {
//     const url = buyStore.getSuccessURL()
//     window.location.href = url
//   }

//   return (
//     <div className="relative">
//       <BuyHeader
//         title={<FormattedMessage id="Card data" />}
//         withBackBtn={!isSuccess}
//       />
//       {isLoading && (
//         <div className="flex h-300 w-full items-center justify-center">
//           <Spinner />
//         </div>
//       )}
//       <div
//         className="relative mt-72 overflow-hidden px-24"
//         id="cloudpayments"
//       ></div>
//       <div className="relative mt-72 overflow-hidden px-24" id="yakassa"></div>
//       {isSuccess && (
//         <SuccessButtonBuy
//           btnText={<FormattedMessage id="Continue" />}
//           onClick={handleClick}
//         />
//       )}
//     </div>
//   )
// }

export function RegistrationPage() {
  const authStore = useAppModule().authStore
  const buyStore = useBuyStore()
  const onboardingStore = useOnboardingStore()
  const state = useSnapshot(buyStore.state)
  const isMobile = useIsMobile()
  const [checkBoxes, setCheckboxes] = useState([false, false])
  // CP Widget states
  //   const [isSuccess, setIsSuccess] = useState(false)
  //   const [isLoading, setIsLoading] = useState(false)

  const handleSetCheckboxes = (index: number) => {
    setCheckboxes((prev) => prev.map((item, i) => (i === index ? !item : item)))
  }

  const planInfo = planInfos[state.plan]

  const onSubmitHandler = async (login: string) => {
    try {
      buyStore.setStateLoading(true)
      if (authStore.state.user?.login != login) {
        await buyStore.registrationWithEmail(login)
        await onboardingStore.updateAccount()
        await buyStore.setUI()
      }
      buyStore.setLogin(login)

      const widgetConstructor = await buyStore.getBuyConstructor()

      //  CLOUDPAYMENTS WIDGET
      //   if (buyStore.state.provider === 'cp') {
      //     const cloudPaymentsWidget = widgetConstructor as {
      //       mount: (element: HTMLElement) => void
      //       on: (
      //         eventName: string,
      //         callback: (result: React.ReactNode) => void,
      //       ) => void
      //       destroy: () => void
      //     }
      //     const paymentElement = document.getElementById('cloudpayments')
      //     if (paymentElement) {
      //       setIsLoading(false)
      //       cloudPaymentsWidget.mount(paymentElement)
      //       cloudPaymentsWidget.on('success', () => {
      //         setIsSuccess(true)
      //       })
      //       cloudPaymentsWidget.on('fail', () => {
      //         setIsSuccess(false)
      //       })
      //     }
      //   } else {
      const yooKassaWidget = widgetConstructor as YaKassaPaymentWidget
      yooKassaWidget.on('complete', () => {
        setTimeout(() => {
          yooKassaWidget.destroy()
          const url = buyStore.getSuccessURL()
          window.location.href = url
        }, 2000)
      })
      yooKassaWidget.render('yakassa')
      buyStore.setStateLoading(false)
    } catch (error) {
      buyStore.setStateLoading(false)
      console.log(error)
    }
  }
  // CP Widget handlers
  //   const handleClick = () => {
  //     const url = buyStore.getSuccessURL()
  //     window.location.href = url
  //   }

  // useEffect(() => {
  //   if (state.showForm) {
  //     divRef.current?.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }, [state.showForm, state.plan])

  return isMobile ? (
    <div className="flex h-full flex-col items-center justify-center">
      <BuyHeader
        title={<FormattedMessage id="Enter email" />}
        withBackBtn={true}
      />
      <div className="mx-16 mt-72 flex h-[calc(100dvh-72px)] w-full max-w-400">
        <RegLoginForm
          registrationWithoutPassword={true}
          disableButton={!state.recurrent || checkBoxes.includes(false)}
          btnText="Продолжить"
          btnLoading={state.loading}
          login={false}
          recurrentText={
            <MobileRecurrentBlock
              plan={planInfo.plan}
              price={planInfo.price.ru}
              checkBoxes={checkBoxes}
              handleSetCheckboxes={handleSetCheckboxes}
            />
          }
          onSubmit={(data) => {
            void onSubmitHandler(data.login)
          }}
        />
      </div>
      <div className="relative mt-72 overflow-hidden px-24" id="yakassa"></div>
    </div>
  ) : (
    <motion.div
      className="flex flex-col items-center xl:min-h-screen xl:w-full xl:bg-light xl:p-64"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <img src="/images/edman-logo.svg" className="w-[310px]" />
      <div className="flex h-full min-h-[770px] w-[1000px] overflow-hidden rounded-32">
        <div className="h-full w-1/2 bg-blue75">
          <div className="flex flex-col">
            <div className="py-32 pl-64 text-22 font-extrabold">
              <FormattedMessage id="subscription.plan" />

              <p className="font-medium">
                <FormattedMessage
                  id="months"
                  values={{ count: planInfos[state.plan].duration }}
                />
              </p>
            </div>
            <Divider />
            <div className="py-16 pl-64 pr-48 text-left text-22 font-extrabold ">
              <FormattedMessage id="subscription.payment" />
              <p className="font-normal">{planInfo.price.ru}₽</p>
              <FreeFor7DaysBlock
                isEmailStep
                textAlign="left"
                planType={state.plan}
              />
            </div>
            <Divider />
            <div className="py-16 pl-64 pr-48 text-left text-22 font-extrabold">
              <FormattedMessage id="free7daysNoRisk" />
              <p className="mt-8 text-14 font-normal">
                <FormattedMessage id="trialInfoSubheader" />
              </p>
            </div>
            <div className="pb-48 pl-64 pr-48 pt-32">
              <ProgressTracker />
            </div>
          </div>
        </div>
        <div className="min-h-[770px]  w-1/2 bg-white px-48 py-32">
          <RegLoginForm
            registrationWithoutPassword={true}
            disableButton={!state.recurrent || state.loading}
            btnText="Продолжить"
            btnLoading={state.loading}
            login={false}
            recurrentText={<DesktopRecurrentBlock plan={planInfo.duration} />}
            onSubmit={(data) => {
              void onSubmitHandler(data.login)
            }}
          />
        </div>
      </div>
      <div className="mt-40 max-w-[700px] text-center text-18 font-extrabold">
        <FormattedMessage id="regularPayments" />
        <span className="block font-normal">
          <FormattedMessage id="afterEndTrial" />
        </span>
      </div>
      <div id="yakassa" />
    </motion.div>
  )
}

function Plan(props: { planType: SubscriptionPlan; level: string }) {
  const planType = props.planType
  const planInfo = planInfos[planType]
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)

  return (
    <div
      className={cn(
        'h-full w-[420px]',
        props.planType == '12M'
          ? ' rounded-[36px] bg-plan-gradient p-8 shadow-plan-drop transition-all duration-300 hover:translate-x-12'
          : ' p-0',
      )}
    >
      <div
        className={cn(
          'relative flex h-full flex-col justify-between rounded-32 bg-white p-24 pt-[48px] transition-all duration-300 ',
          state.plan == planType ? 'relative z-10' : '',
          props.planType == '1M' && 'hover:-translate-x-12',

          //   state.plan == planType
          //     ? 'border-blue4 bg-white hover:!bg-white group-hover:bg-purple-light2'
          //     : 'border-purple-light2 bg-purple-light2 hover:border-purple-light hover:bg-purple-light',
        )}
      >
        {/* {props.planType == '3M' && (
          <DiscountMonthsImage className="absolute -right-44  -top-24 z-10" />
        )} */}
        {props.planType == '12M' && (
          <DiscountYearImage className="absolute right-[-32px] top-[-46px] z-20" />
        )}
        <div className="flex flex-col items-center">
          <div className="flex flex-col gap-24 text-center text-[40px] font-bold">
            <FormattedMessage
              id="months"
              values={{ count: planInfo.duration }}
            />

            <div className="text-32 font-extrabold text-blue2">
              {planInfo.price.ru}₽
            </div>
            <FreeFor7DaysBlock planType={props.planType} />
          </div>
        </div>
        <PlanButton planType={props.planType} />
        {/* <SubscriptionContent
          className="ml-20"
          planType={planType}
          level={props.level}
        /> */}
      </div>
    </div>
  )
}

function PlanButton(props: { planType: SubscriptionPlan }) {
  const authStore = useAppModule().authStore
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)
  const navigate = useNavigate()
  return (
    <Button
      size="extralarge"
      rounded="full"
      disabled={state.loading || !state.recurrent}
      className="mt-12"
      id={'subscription_trial_' + props.planType}
      bg={props.planType == '1M' ? 'gray-gradient' : 'blue-gradient'}
      onClick={() => {
        buyStore.setPlan(props.planType)
        navigate(urls.subscriptions('paywall'))
        authStore.trackEvent('subscribtion_trial_click')
      }}
    >
      <FormattedMessage id="Start your 7-day free trial" />
    </Button>
  )
}

function PlanMobile(props: { planType: SubscriptionPlan; level: string }) {
  const planType = props.planType
  const planInfo = planInfos[planType]
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)
  const currentPlan = planType == state.plan

  return (
    <div
      className={cn(
        'overflow-hidden rounded',
        currentPlan ? 'shadow-drop' : '',
      )}
    >
      {planInfo.discount && (
        <div className="flex h-32 items-center justify-center bg-blue4 text-14 font-extrabold uppercase text-white">
          экономия {planInfo.discount}
        </div>
      )}
      <div
        className={cn(
          'cursor-pointer overflow-hidden bg-light p-16 transition-colors ',
          currentPlan ? 'border-blue4 ' : 'border-transparent',
          planInfo.discount
            ? 'rounded-b border-x-4 border-b-4'
            : 'rounded border-4',
        )}
        onClick={() => {
          buyStore.setPlan(props.planType)
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-center gap-24">
            <div
              className={cn(
                'flex size-22 shrink-0 items-center justify-center rounded-full bg-white transition-all',
                currentPlan ? 'border-2 border-blue4' : 'border-2 border-gray5',
              )}
            >
              {currentPlan && (
                <div className="size-12 rounded-full bg-blue4 transition-all " />
              )}
            </div>
            <div className="flex-1">
              <div className="text-20 font-extrabold leading-[32px]">
                <FormattedMessage
                  id="Months"
                  values={{ count: planInfo.duration }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex items-start align-top font-extrabold text-blue4">
              <span className="text-32 leading-none">{planInfo.price.ru}</span>
              <span className="relative top-2 text-18 leading-none">₽</span>
            </div>
            {planType === '1M' && (
              <div className="text-14 font-semibold leading-none text-violetd">
                <FormattedMessage id="per month" />
              </div>
            )}
          </div>
        </div>
        {/*
        <AnimatePresence>
          {currentPlan && (
            <motion.div
              className="overflow-hidden"
              initial={{ opacity: 0, height: '0' }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: '0' }}
            >
              <PlanButton planType={props.planType} />
              <SubscriptionContent planType={planType} level={props.level} />
            </motion.div>
          )}
        </AnimatePresence> */}
      </div>
    </div>
  )
}

export const AnimateChangeInHeight = (props: {
  className?: string
  state: unknown
  children: React.ReactNode
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<number | 'auto'>('auto')

  useEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.offsetHeight)
    }
  }, [props.state])

  return (
    <motion.div
      className={cn('overflow-hidden', props.className)}
      transition={{ duration: 0.3 }}
      animate={{ height }}
    >
      <motion.div
        key={String(props.state)}
        transition={{ delay: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        ref={containerRef}
      >
        {props.children}
      </motion.div>
    </motion.div>
  )
}

function WhiteBlockDesktop() {
  return (
    <div className="relative mt-32 size-full">
      <div className="absolute left-1/2 top-80 z-10 flex -translate-x-1/2 items-end gap-20">
        <ImgBranchesBlock
          size="small"
          topElement={<p className="text-20 font-extrabold ">3M+</p>}
          bottomElement={<p className="text-18 font-extrabold ">Users</p>}
        />
        <ImgBranchesBlock
          size="large"
          topElement={<p className="text-32 font-black">4.9</p>}
          bottomElement={
            <div className="flex items-center gap-0">
              {Array(5)
                .fill(0)
                .map((_, i) => (
                  <StarIcon key={i} className="size-14" />
                ))}
            </div>
          }
        />
        <ImgBranchesBlock
          size="small"
          topElement={<p className="text-20 font-extrabold ">55k+</p>}
          bottomElement={<p className="text-18 font-extrabold ">Reviews</p>}
        />
      </div>
      <div className="relative h-[150px] w-[100vw] overflow-hidden">
        <div className="absolute bottom-[-7850px] left-1/2 h-[8000px] w-[400%] -translate-x-1/2 rounded-[50%] bg-white" />
      </div>
      <ReviewsBlock />
      <FaqBlock />
      <ContactsView compact isSubscriptionView />
    </div>
  )
}

function SubscriptionViewDesktop() {
  return (
    <>
      <Header />
      {/* <ChoosePlanTitle /> */}
      <PlansBlock />
      <FullExperienceBlock />
      <SecurityBlock />
      <WhiteBlockDesktop />
    </>
  )
}

function SubscriptionViewMobile() {
  return (
    <div className="mb-[180px] flex flex-col items-center overflow-y-scroll bg-white pt-16">
      {/* <div className="mb-12 text-center text-[40px] font-extrabold leading-none">
        <FormattedMessage id="subscription.title" />
        <div className="text-blue4">
          <FormattedMessage id="subscription.subtitle" />
        </div>
      </div> */}

      {/* <div className="mb-8 text-center text-16 font-semibold">
        <FormattedMessage id="subscription.description" />
      </div>
      <ProgramLink />

      <Rive
        className="relative h-[80px] w-full"
        src="/animations/soundwave_background_mobile.riv"
      /> */}
      {/*
      <CarouselBlock /> */}

      <div className="mt-16 flex flex-col gap-16 px-16">
        <div className="text-center text-36 font-extrabold leading-none">
          <FormattedMessage id="subscription.choose" />
        </div>
        <PlanMobile level="B1" planType="12M" />
        <PlanMobile level="B1" planType="1M" />
        {/* <PlanMobile level="B1" planType="3M" /> */}
      </div>
      {/* <SecurityBlock /> */}
      <FullExperienceBlock />
      <BottomButtonBlock />
      <ReviewsBlock />
      <FaqBlock />
      <ContactsView compact isSubscriptionView />
    </div>
  )
}

const FullExperienceBlock = () => {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  return (
    <div className="mt-32 px-24">
      <h3 className="text-center text-22 font-extrabold text-black">
        <FormattedMessage id="Unlock the full experience" />
      </h3>
      <div className="mt-14 flex flex-col gap-20 xl:mt-32 xl:grid xl:max-w-[900px] xl:grid-cols-2 xl:gap-x-40 xl:gap-y-32">
        <div className="xl:space-y-32">
          {EXP_BLOCKS.slice(0, 3).map((item, i) => (
            <ExperienceBlock
              item={item}
              key={`exp-block-${i}`}
              goal={state.info.goal}
            />
          ))}
        </div>
        <div className="xl:space-y-32">
          {EXP_BLOCKS.slice(3).map((item, i) => (
            <ExperienceBlock
              item={item}
              key={`exp-block-${i + 3}`}
              goal={state.info.goal}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const ExperienceBlock = ({
  item,
  goal,
}: {
  item: IExpBlock
  goal?: string
}) => {
  return (
    <div className="flex items-center gap-6">
      <img src={item.img} alt={item.alt} className="size-[76px]" />
      <div className="flex flex-col gap-2">
        <p className="text-14 font-extrabold text-black">
          <FormattedMessage id={item.title} />
        </p>
        <p className=" text-14 font-semibold text-gray5">
          <FormattedMessage id={getDescId(item.desc, goal) as AppMessageKeys} />
        </p>
      </div>
    </div>
  )
}

const FreeFor7DaysBlock = ({
  planType,
  isEmailStep = false,
  textAlign = 'center',
}: {
  planType: SubscriptionPlan
  isEmailStep?: boolean
  textAlign?: 'center' | 'left'
}) => {
  return (
    <div className={cn('xl:text-14 xl:font-normal', textAlign)}>
      <p className="w-full">
        <FormattedMessage
          id={isEmailStep ? 'freeFor7DaysEmailStep' : 'freeFor7DaysWithBr'}
          values={{
            br: <br />,
            price: (
              <span className="font-extrabold">
                {planInfos[planType].price.ru}
              </span>
            ),
            monthPlan: (
              <span className="font-extrabold">
                <FormattedMessage
                  id={`every${planInfos[planType].plan}` as AppMessageKeys}
                />
              </span>
            ),
          }}
        />
      </p>
      {!isEmailStep && (
        <p>
          <FormattedMessage id="cancelAnytime" />
        </p>
      )}
    </div>
  )
}

const BottomButtonBlock = () => {
  const buyStore = useBuyStore()
  const authStore = useAppModule().authStore
  const navigate = useNavigate()
  const state = useSnapshot(buyStore.state)
  const currentPlan = state.plan
  return (
    <div className="fixed bottom-0 flex h-fit w-full max-w-800 flex-col gap-16 bg-white p-16 pt-0">
      <div className="h-1 w-full bg-purple3 " />
      <FreeFor7DaysBlock planType={currentPlan} />
      <Button
        bg="blue-gradient-shadow-inset"
        size="extralarge"
        rounded="full"
        className="w-full"
        onClick={() => {
          buyStore.setPlan(planInfos[currentPlan].plan as SubscriptionPlan)
          navigate(urls.subscriptions('paywall'))
          authStore.trackEvent('subscribtion_trial_click')
        }}
      >
        <FormattedMessage id="Start your 7-day free trial" />
      </Button>
    </div>
  )
}
