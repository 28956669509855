import { classed } from '@tw-classed/react'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { SwitchLanguage } from '../../shared/ui/switchLanguage/SwitchLanguage.tsx'
import { cn } from '../../shared/lib/utils.ts'

const VOCAB_PATH = '/vocabulary'

export function AppHeaderNavigationButton(props: { white?: boolean }) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleNavigate = () => {
    const nextUrl = location.pathname.startsWith(VOCAB_PATH)
      ? urls.practice
      : urls.program
    navigate(nextUrl)
  }
  return (
    <button
      onClick={() => {
        handleNavigate()
      }}
      className={cn('w-24 ', props.white && 'mxs:text-black')}
    >
      <Icon iconName="left" />
    </button>
  )
}

export const AppHeaderContainer = classed.div(
  'relative flex h-104 shrink-0 items-center gap-16 bg-violetd px-16 text-28 font-semibold text-white sm:px-36',
)

export function AppHeader(props: {
  backButton?: boolean
  children?: React.ReactNode
  className?: string
}) {
  return (
    <AppHeaderContainer className={props.className}>
      {props.backButton && <AppHeaderNavigationButton />}
      {props.children}
      <LangSwitcher />
    </AppHeaderContainer>
  )
}

export function LangSwitcher(props: {
  top?: boolean
  darkBorder?: boolean
  className?: string
  grayText?: boolean
}) {
  const appStore = useAppModule().appStore
  const state = useSnapshot(appStore.state)
  return (
    <div
      className={cn(
        'flex justify-end rounded-8',
        props.top && 'sm:absolute sm:right-0 sm:top-0',
        props.className,
      )}
    >
      <SwitchLanguage
        darkBorder={props.darkBorder}
        grayText={props.grayText}
        language={state.language}
        onChange={(lang) => {
          appStore.setLanguage(lang)
        }}
      />
    </div>
  )
}
