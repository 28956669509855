import { proxy } from 'valtio'
import {
  ChatApi,
  ChatMessage,
  demoLessonTag,
  ExerciseInfo,
  HelpLessonTag,
  InfoBlockType,
  LessonStages,
  LessonType,
  ProgramItem,
  Vocabulary,
} from '../../shared/api/chatApi.ts'
import { SpeechService } from '../speech/speechService.ts'
import { AuthStore } from '../auth/authStore.ts'
import {
  emptyFn,
  filterMetrics,
  getLast,
  getTextTag,
  IFilteredMetrics,
  isMobileByUserAgent,
  nilMap,
  Payload,
  removeTextTag,
  sortBy,
  startTimer,
  waitFor,
} from '../../shared/lib/utils.ts'
import { convertToId, toUppercase } from '../../shared/lib/stringUtils.ts'
import { urls } from '../../shared/urls.ts'
import { AppStore } from '../whatisnew/appStore.ts'
import { ProgressApi } from '../../shared/api/progressApi.ts'
import {
  ChatMessageWithIndex,
  IDataMessageRequest,
  isExercise,
} from '../chats/chatStore.ts'
import { IWrongAnswers } from '../exercises/matchWords/matchWordsStore.ts'
import { LangLevels } from '../../shared/data/onboardingSteps.ts'
type LoadingState = 'chat' | 'response' | 'record' | 'analyze' | 'command'

interface State {
  exerciseResultText?: string
  correctAnswersCount?: number | null
  wrongAnswersCount?: number | null
  wrongAnswers?: IWrongAnswers
  payload?: Payload[] | null
  lessonType: LessonType
  lastMessage: ChatMessageWithIndex
  languageLevel?: LangLevels
  userId?: number
  url?: string
  lastMessageId: number | undefined
  //   onlyTextInput: boolean
  program?: ProgramItem
  isDemo: boolean
  isHelpLesson: boolean
  isHelpVocabLesson: boolean
  isLoading: boolean
  completed: boolean
  lessonCompleted: boolean
  speakRussian: boolean
  vocabulary: Vocabulary[]
  selectedWords: { word: string; dark: boolean }[]
  showHistory: boolean
  showMessageTexts: boolean
  infoBlock?: InfoBlockType
  messages: ChatMessageWithIndex[]
  exercise: ChatMessageWithIndex | null
  loading?: LoadingState
  programTag: string
  nextProgramTag: string | null
  contentModal: boolean
  stages?: LessonStages
  currentStage?: string
  responseLoadingText?: string // TODO replace with matching
  currentText: string
  chatId: number
  loadingMessage: ChatMessageWithIndex | undefined
  showLoadingMessage: boolean
  sendButtonDisabled: boolean
  disabledByLoading: boolean
  chatInputUnavailable: boolean
  isExercise: boolean
  currentTaskIndex: number
  inputDisabled: boolean
  showChatBlock: boolean
  showTextInput: boolean
  mute: boolean
  statistics: {
    isLessonCompletedPage: boolean
    isStatPage: boolean
    isLoading: boolean
    metrics: IFilteredMetrics | null
    username: string | null
  }
  playingMessage?: {
    messageId: number
    progress: number
  }
  lessonRate: null | 1 | 2 | 3
  currentIndex: number
  exerciseCount: number | null
  exercises: readonly ExerciseInfo[] | null
  isLastExercise: boolean
}

const defaultState = (chatId: number): State => ({
  get lastMessage() {
    return (
      this.messages[this.messages.length - 1] ?? {
        id: 1,
        message_text: '',
        text: '',
        is_ai: true,
        is_last: false,
      }
    )
  },
  get selectedWords() {
    return this.vocabulary.map((x) => ({
      word: x.word_original,
      dark: x.is_user_added,
    }))
  },
  get speakRussian() {
    if (this.languageLevel == 'A2') {
      return true
    }
    if (!this.program) {
      return false
    }
    return (
      this.program.level == 'A2' &&
      this.program.lesson_type == 'General conversation'
    )
  },
  get url() {
    if (this.isDemo) {
      return nilMap(this.lastMessage.state, (x) => urls.demo(convertToId(x)))
    }

    if (!this.currentStage) {
      return undefined
    }
    const id = convertToId(this.currentStage)
    return urls.lesson(this.programTag, id)
  },
  mute: false,
  showTextInput: false,
  get lastMessageId() {
    return getLast(this.messages)?.id
  },
  //   get onlyTextInput() {
  //     return this.infoBlock == 'demo_username'
  //   },
  showChatBlock: false,
  contentModal: false,
  get isDemo() {
    return this.programTag == demoLessonTag
  },
  get isHelpLesson() {
    return HelpLessonTag(this.programTag)
  },
  isHelpVocabLesson: false,
  vocabulary: [],
  programTag: '',
  nextProgramTag: null,
  get completed() {
    return this.program?.status == 'completed'
  },
  get lessonCompleted() {
    return !!getLast(this.messages)?.is_last
  },
  showMessageTexts: true,
  showHistory: false,
  messages: [],
  currentText: '',
  chatId,

  get loadingMessage() {
    const newMessage: ChatMessageWithIndex = {
      index: this.messages.length,
      message_text: '',
      text: '',
      is_ai: this.loading == 'response',
      id: -1,
      is_last: false,
      statePlayBtn: 'play',
    }

    return this.showLoadingMessage ? newMessage : undefined
  },

  get showLoadingMessage() {
    const showLoadingMessageStates: LoadingState[] = ['response', 'analyze']
    return this.loading
      ? showLoadingMessageStates.includes(this.loading)
      : false
  },

  get isExercise() {
    const lastMessage = getLast(this.messages)
    return isExercise(lastMessage?.content)
  },
  get chatInputUnavailable() {
    return this.isExercise || this.lessonCompleted
  },

  get disabledByLoading() {
    const completeButtonDisabledStates: LoadingState[] = [
      'response',
      'analyze',
      'command',
    ]
    return !!this.loading && completeButtonDisabledStates.includes(this.loading)
  },

  get sendButtonDisabled() {
    return this.disabledByLoading || this.chatInputUnavailable
  },
  get inputDisabled() {
    const lastMessage = getLast(this.messages)
    return this.lessonCompleted || isExercise(lastMessage?.content)
  },
  statistics: {
    isLessonCompletedPage: false,
    isStatPage: false,
    isLoading: false,
    metrics: null,
    username: null,
  },

  get lessonRate() {
    const lastMessage = getLast(this.messages)
    return this.isHelpLesson ? 3 : lastMessage?.ai_rate?.rate ?? null
  },
  lessonType: null,
  exercise: null,
  isLoading: false,
  get isLastExercise() {
    return this.currentIndex === this.exerciseCount
  },
  currentIndex: 1,
  currentTaskIndex: 0,
  exerciseCount: null,
  get exercises() {
    const lastMessage = getLast(this.messages)
    return lastMessage?.exercises?.exercises ?? null
  },
})
export class VocabLessonStore {
  state: State

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
    private speechService: SpeechService,
    private appStore: AppStore,
    private progressApi: ProgressApi,
  ) {
    this.state = proxy<State>(defaultState(0))
  }

  async updateStage() {
    if (this.state.programTag) {
      const stage = await this.chatApi.userProgramStage(this.state.programTag)
      this.state.currentStage = stage.stage
    }
  }

  goToLessonCompletedPage() {
    this.state.statistics.isLessonCompletedPage = true
    if (this.state.isDemo) {
      this.authStore.trackOnbMetrika('demo_finished')
    }
  }

  async goToLessonStat() {
    if (!this.state.programTag) return
    this.state.statistics.isStatPage = true
    this.state.statistics.isLoading = true

    // const data = await this.progressApi.getCurrentLessonMetrics(
    //   this.state.programTag,
    // )
    const data = await this.progressApi.getMetrics()
    const { name: username } = await this.chatApi.getAccount()

    this.state.statistics = {
      isLessonCompletedPage: false,
      isLoading: false,
      isStatPage: true,
      metrics: filterMetrics(data, this.state.programTag),
      username,
    }
  }

  resetLessonStat() {
    this.state.statistics = {
      isLessonCompletedPage: false,
      isStatPage: false,
      isLoading: false,
      metrics: null,
      username: null,
    }
  }

  async newChat(
    tag: string,
    recreate = false,
    lessonType: LessonType = 'lesson',
    parameters: { user_vocab: string[] } | null = null,
    isHelpVocabLesson?: boolean,
  ) {
    if (!this.authStore.state.user) {
      return
    }
    try {
      this.state.isLoading = true
      this.state.programTag = tag
      void this.logUserDevice()
      this.resetState()
      this.resetLessonStat()
      this.speechService.init()
      await this.updateLanguageLevel()
      this.setLoading('chat')
      this.state.showChatBlock = true
      this.setLessonType(lessonType)
      void this.chatApi.patchAccount({
        session_metadata: {
          programTag: this.state.programTag,
          lessonType,
        },
      })
      const [chat] = await Promise.all([
        this.chatApi.chat_create({
          user: this.authStore.state.user.user_id,
          program_tag: tag,
          chat_title: tag,
          language: 'EN',
          recreate,
          ...(parameters ? { parameters } : {}),
        }),
        this.updateLanguageLevel(),
      ])
      await this.load(chat.id)
    } finally {
      this.state.isLoading = false
      this.state.loading = undefined
      if (isHelpVocabLesson) {
        this.state.isHelpVocabLesson = isHelpVocabLesson
      }
    }
  }

  async loadStages() {
    this.state.stages = await this.chatApi.userProgramStages(
      this.state.programTag,
    )
    await this.updateStage()
  }

  get currentTextIsEmpty() {
    return !this.state.currentText
  }

  removeLastMessage() {
    this.state.messages.pop()
  }

  //   addDemoLevelInfoBlock() {
  //     this.state.infoBlock = 'demo_level'
  //     this.state.loading = undefined
  //   }

  setLoading(loading: LoadingState) {
    this.state.loading = loading
  }

  async waitForFirstMessage() {
    await waitFor(() => this.state.messages.length > 0, 100)
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setCurrentContent(message: ChatMessageWithIndex) {
    // if (message.picture_url) {
    //   message.content = { image: message.picture_url }
    //   this.authStore.trackMetrika('demo2_photo_received')
    //   if (this.state.isDemo) {
    //     this.authStore.trackMetrika('picture_demo_sent')
    //   }
    // }
    // if (message.article) {
    //   message.content = {
    //     article: {
    //       title: message.article.header,
    //       body: message.article.body,
    //     },
    //   }
    // }
    // if (message.true_or_false) {
    //   message.content = { trueOrFalse: message.true_or_false }
    //   this.setExercise(message)
    // }
    // if (message.listening) {
    //   message.content = {
    //     listening: message.listening.map((x) => ({
    //       ...x,
    //       soundUrl: x.sound_url,
    //     })),
    //   }
    //   this.setExercise(message)
    // }
    if (message.fill_the_gaps2) {
      const language = toUppercase(this.appStore.state.language)

      message.content = {
        fillTheGaps: message.fill_the_gaps2.map((x) => ({
          incorrect: x.incorrect[language],
          title: x.title[language],
          options: x.options,
          text: x.text,
          vocabItem: x.vocab_item,
        })),
      }
      this.setExercise(message)
    }
    if (message.match_words) {
      message.content = { matchWords: message.match_words }
      this.setExercise(message)
    }
    // if (message.write_the_gaps) {
    //   message.content = { writeTheGaps: message.write_the_gaps }
    //   this.setExercise(message)
    // }
    const textTag = getTextTag(message.message_text)
    if (textTag) {
      message.content = { text: textTag }
    }
  }
  addMessage(message: ChatMessage) {
    const text = removeTextTag(message.message_text)

    const newMessage: ChatMessageWithIndex = {
      ...message,
      text: text,
      index: this.state.messages.length,
      statePlayBtn: 'play',
    }
    // if (fromLoad) {
    //   newMessage.ai_comment = message.ai_comment ?? []
    // }
    this.setCurrentContent(newMessage)
    if (message.exercises) {
      this.state.currentIndex = message.exercises.currentIndex
      this.state.exerciseCount = message.exercises.exercise_count
      this.state.exercises = message.exercises.exercises
      this.state.currentTaskIndex = 0
    }

    if (!newMessage.is_hidden) {
      this.state.messages.push(newMessage)
    }

    // if (
    //   this.state.languageLevel == 'A2' &&
    //   newMessage.is_ai &&
    //   newMessage.translation
    // ) {
    //   this.state.lastMessage.showTranslation = true
    // }
    return newMessage
  }

  resetState() {
    this.state.messages = []
    this.state.infoBlock = undefined
    this.state.exerciseCount = null
    this.state.currentIndex = 1
  }

  async updateLanguageLevel() {
    const account = await this.chatApi.getAccount()
    this.setLanguageLevel(account.level)
  }

  async loadProgram() {
    if (this.state.isDemo) {
      return
    }
    const programs = await this.chatApi.userPrograms()
    this.state.program = programs.find((x) => x.tag == this.state.programTag)
  }
  async load(chatId: number) {
    if (this.state.chatId == chatId) {
      return
    }
    this.state.isLoading = true
    this.authStore.trackMetrika('demo2_lesson_launched')

    this.state.chatId = chatId
    this.setLoading('chat')

    const [messages] = await Promise.all([
      this.chatApi.message_list(this.state.chatId),
      this.loadProgram(),
      this.loadStages(),
      // this.loadWords(),
    ])
    for (const m of sortBy(messages, (x) => x.id)) {
      this.addMessage(m)
    }
    if (this.state.messages.length == 1) {
      await this.play(this.state.messages[0].id, false)
    }

    this.state.loading = undefined
    this.state.isLoading = false
    if (this.authStore.state.user) {
      this.state.userId = this.authStore.state.user.user_id
    }
    if (this.state.isDemo) {
      this.authStore.trackOnbMetrika('1st_question')
    }
  }

  async sendCurrentText() {
    if (this.state.loading) {
      return
    }
    const text = this.state.currentText
    this.state.currentText = ''
    await this.send(text)
  }

  //   handleCommand(text: string) {
  //     if (text == '/text') {
  //       this.state.lastMessage.content = {
  //         text: 'Tom is in France on his first trip. He goes to a big supermarket to find food. He wants to buy milk, water, and some French cheese. Tom takes a basket and starts looking for the dairy section. He sees many types of cheese and picks one that looks tasty. ',
  //       }
  //     }
  //     if (text == '/end') {
  //       this.endChat()
  //     }
  //     if (text == '/fillgaps') {
  //       this.state.lastMessage.content = { fillTheGaps }
  //       this.state.showTextInput = false
  //     }
  //     if (text == '/task1') {
  //       this.state.lastMessage.content = { trueOrFalse }
  //       this.state.showTextInput = false
  //     }
  //     if (text == '/task2') {
  //       this.state.lastMessage.content = { matchWords }
  //     }
  //     if (text == '/task3') {
  //       this.state.lastMessage.content = { writeTheGaps }
  //     }
  //     if (text == '/image') {
  //       this.state.lastMessage.content = {
  //         image:
  //           'https://img.dev.edman.ai/278/20240322/49b5e6fc-0a50-4faf-83bd-c10b2a94e906.png',
  //       }
  //     }
  //   }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async send(
    text: string,
    //hidden = false,
    correctAnswersCount: number | null = null,
    payload: Payload[] | null = null,
    wrongAnswersCount: number | null = null,
    wrongAnswers?: IWrongAnswers,
  ) {
    if (Boolean(this.state.isLoading) || !text) {
      return
    }
    try {
      this.state.isLoading = true
      await this.waitForFirstMessage()

      //const lastMessage = this.state.lastMessage
      // const fakeUserMessageId = lastMessage.id + 1
      //   const userMessage = this.addMessage({
      //     is_ai: false,
      //     message_text: text,
      //     is_hidden: hidden,
      //     id: fakeUserMessageId,
      //     is_last: false,
      //   })

      //   if (this.state.isDemo && this.state.infoBlock) {
      //     this.authStore.trackMetrika(`${this.state.infoBlock}_answer`)
      //     if (this.state.infoBlock == 'demo_subtopic') {
      //       this.state.showChatBlock = true
      //       this.state.messages = []
      //     }
      //     this.state.infoBlock = undefined
      //   }
      const getData = () => {
        const data: IDataMessageRequest = {
          chat: this.state.chatId,
          message_text: text,
        }
        if (
          Number.isInteger(correctAnswersCount) &&
          correctAnswersCount !== null
        ) {
          data.exercise_result = {
            correct: correctAnswersCount,
            payload: null,
            wrongAnswersCount: null,
          }
          data.parent_msg = this.state.lastMessageId
        }
        if (payload !== null && data.exercise_result) {
          data.exercise_result.payload = payload
        }
        if (wrongAnswersCount !== null && data.exercise_result) {
          data.exercise_result.wrongAnswersCount = wrongAnswersCount
        }
        if (wrongAnswers && data.exercise_result) {
          data.exercise_result.wrongAnswers = wrongAnswers
        }
        return data
      }
      const timer = startTimer()
      const realUserMessageId = (await this.chatApi.message_create(getData()))
        .id
      // userMessage.id = realUserMessageId
      const [newMessages] = await Promise.all([
        this.chatApi.message_process(realUserMessageId),
        // this.chatApi.message_orthography_check(realUserMessageId),
      ])
      //stopResponseTextTimeout()
      for (const newMessage of newMessages) {
        void this.chatApi.patchMessage(newMessage.id, {
          duration_front: timer.stop(),
        })

        this.addMessage(newMessage)
        // this.updateAiComment(orthographyCheck)
        // void this.loadWords()
        //  void this.updateStage()

        this.state.loading = undefined
        this.state.isLoading = false
        //  await this.play(newMessage.id, false)
      }
    } catch {
      this.removeLastMessage()
    } finally {
      //stopResponseTextTimeout()
      //this.state.loading = undefined
      this.state.isLoading = false
      //   if (this.state.lessonCompleted) {
      //     this.authStore.trackMetrika('demo2_lesson_finished')
      //   }
    }
  }

  updateAiComment(message: ChatMessage) {
    const updateMessage = this.state.messages.find((x) => x.id == message.id)
    if (updateMessage) {
      updateMessage.ai_comment = message.ai_comment
    }
  }

  toggleMessageAiComment(id: number) {
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      message.showAiComment = !message.showAiComment
    }
  }

  async toggleMessageTranslate(id: number) {
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      if (message.showTranslation) {
        message.showTranslation = false
      } else {
        message.showTranslation = true
        const translation = await this.chatApi.message_translate(id)
        message.translation = translation.translation
      }
    }
  }

  async showMessageHint() {
    const id = this.state.lastMessage.id
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      message.showHint = true
      if (!message.reply_hint) {
        const translation = await this.chatApi.message_reply_hint(id)
        message.reply_hint = translation.reply_hint
      }
    }
  }

  async play(messageId: number, forcePlay: boolean, hint = false) {
    const message = this.state.messages.find((x) => x.id == messageId)
    if (!message || (this.state.mute && !forcePlay)) {
      return
    }
    try {
      if (!forcePlay) {
        this.state.loading = 'command'
      }
      const text = hint ? message.reply_hint?.text : message.text
      if (!text) {
        return
      }
      const id = hint ? -messageId : messageId
      await this.speechService.playText(
        id,
        this.state.chatId,
        text,
        (progress) => {
          this.state.playingMessage = {
            messageId: message.id,
            progress,
          }
        },
      )
    } finally {
      if (!forcePlay) {
        this.state.loading = undefined
        this.state.isLoading = false
      }
      //   if (this.state.availableHints.includes('clickWord')) {
      //     this.setShouldViewHint(true, 'clickWord')
      //   }
    }
  }

  //   startResponseTextTimeout() {
  //     const id = setTimeout(() => {
  //       this.state.responseLoadingText = randomElement(responseTexts)
  //     }, responseTextTimeout)
  //     return () => {
  //       this.state.responseLoadingText = undefined
  //       clearTimeout(id)
  //     }
  //   }

  setCurrentText(text: string) {
    this.state.currentText = text
  }

  endChat() {
    this.state.lastMessage.is_last = true
  }

  //   async loadWords() {
  //     if (this.state.programTag) {
  //       const vocabulary = await this.chatApi.vocabulary(this.state.programTag)
  //       this.state.vocabulary = vocabulary
  //     }
  //   }

  //   async removeWord(id: number) {
  //     this.state.vocabulary = this.state.vocabulary.filter((x) => x.id != id)
  //     await this.chatApi.vocabulary_delete(id)
  //   }

  //   async addToDictionary(word: string) {
  //     const newWord: Vocabulary = {
  //       id: 0,
  //       term_text: word,
  //       timestamp: '',
  //       program_tag: '',
  //       topic: '',
  //       is_user_added: true,
  //     }
  //     this.state.vocabulary.push(newWord)
  //     const index = this.state.vocabulary.length - 1

  //     this.playWord(word)

  //     const updateWord = await this.chatApi.vocabulary_add({
  //       chat_id: this.state.chatId,
  //       term_text: word,
  //     })
  //     this.state.vocabulary[index] = updateWord
  //   }

  playWord(word: string) {
    void this.speechService.playText(
      undefined,
      this.state.chatId,
      word,
      emptyFn,
    )
  }

  onExerciseComplete(
    result: string,
    correctAnswersCount?: number,
    payload?: Payload[],
    wrongAnswersCount?: number | null,
    wrongAnswers?: IWrongAnswers | null,
  ) {
    this.authStore.trackMetrika('demo2_puzzle_passed')
    this.state.exerciseResultText = result

    if (Number.isInteger(correctAnswersCount)) {
      this.state.correctAnswersCount = correctAnswersCount
    }
    if (Number.isInteger(wrongAnswersCount)) {
      this.state.wrongAnswersCount = wrongAnswersCount
    }
    if (wrongAnswers) {
      this.state.wrongAnswers = wrongAnswers
    }

    if (payload) {
      this.state.payload = payload
    }
    this.continueAfterExercise()
  }

  onTaskComplete(currentIndex: number) {
    this.state.currentTaskIndex = currentIndex
  }

  continueAfterExercise() {
    if (!this.state.exerciseResultText) {
      return
    }

    void this.send(
      this.state.exerciseResultText,
      //true,
      this.state.correctAnswersCount,
      this.state.payload,
      this.state.wrongAnswersCount,
      this.state.wrongAnswers,
    )
    this.state.exerciseResultText = undefined
    this.state.correctAnswersCount = null
    this.state.wrongAnswersCount = null
    this.state.payload = null

    this.state.loading = 'chat'
    this.state.isLoading = true
  }

  setContentModal(contentModal: boolean) {
    this.state.contentModal = contentModal
  }

  toggleMute() {
    this.state.mute = !this.state.mute
  }
  setShowTextInput(showTextInput: boolean) {
    this.state.showTextInput = showTextInput
  }

  stopEdmanAudio() {
    this.speechService.stopCurrentSound()
  }

  rateChat(rating?: number, experience?: number, user_comment?: string) {
    return this.chatApi.chat_rate(
      this.state.chatId,
      rating,
      experience,
      user_comment,
    )
  }
  setLanguageLevel(level: LangLevels) {
    this.state.languageLevel = level
  }

  setLessonType(type: LessonType) {
    this.state.lessonType = type
    this.state.mute = type === 'vocabulary'
  }

  setExercise(exercise: ChatMessageWithIndex) {
    this.state.exercise = exercise
  }
  async logUserDevice() {
    const userId = this.authStore.state.user?.user_id
    if (userId) {
      const deviceType = isMobileByUserAgent() ? 'mobile' : 'desktop'
      await this.chatApi.logDevice(userId, deviceType)
    }
  }
}
