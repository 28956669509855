import { parseISO, format } from 'date-fns'
import { RefObject, useEffect, useState } from 'react'
import { enUS, ru } from 'date-fns/locale'
import { AppLanguages } from '../translations/messages.ts'
import { FillWordTextData } from '../data/exercises.ts'

export type PickPublic<T> = Pick<T, keyof T>
export function nilMap<T, R>(
  value: T | undefined,
  fn: (item: T) => R,
): R | undefined {
  if (value === undefined) {
    return undefined
  }
  return fn(value)
}

export function saveBlob(blob: Blob, name: string) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()
  URL.revokeObjectURL(url)
}

export function delay(ms: number) {
  return new Promise<void>((resolve) =>
    setTimeout(() => {
      resolve(undefined)
    }, ms),
  )
}

export async function waitFor(fn: () => boolean, interval = 100) {
  for (let i = 0; i < 100; i++) {
    if (fn()) {
      return
    }
    await delay(interval)
  }
}

export function getFormObject(form: HTMLFormElement) {
  return Object.fromEntries(new FormData(form).entries()) as Record<
    string,
    string
  >
}

export type ClassValue = string | number | null | boolean | undefined

export function cn(...classes: ClassValue[]) {
  return classes.filter(Boolean).join(' ')
}

export async function playAudio(blob: Blob) {
  const audioUrl = URL.createObjectURL(blob)
  const audio = new Audio(audioUrl)
  await audio.play()
}

export function removeNonLettersAndPunctuation(input: string): string {
  return input.replace(/[^a-zA-Zа-яА-Я0-9\s.,!?;:()']/g, '')
}

export function clearTextForSpeech(input: string): string {
  return removeNonLettersAndPunctuation(removeTextTag(input))
}

export function removeTextTag(input: string): string {
  return input
    .replace(/<text>.*?<\/text>/gs, '')
    .replace(/---.*?---/gs, '')
    .replace(/```.*?```/gs, '')
}

export function getTextTag(input: string): string {
  return (
    input.match(/<text>(.*?)<\/text>/s)?.[1] ??
    input.match(/---(.*?)---/s)?.[1] ??
    input.match(/```(.*?)```/s)?.[1] ??
    ''
  )
}

export function formatTimer(timerMS: number): string {
  const minutes = Math.floor(timerMS / 60000)
  const seconds = Math.floor((timerMS % 60000) / 1000)
  const ms = timerMS % 1000
  return `${minutes}:${String(seconds).padStart(2, '0')},${
    String(ms).padStart(3, '0')[0]
  }`
}

export function removeOnlyTextTag(input: string): string {
  return input.replace(/<\/?text>/g, '')
}

export function randomIndex(max: number) {
  return Math.floor(Math.random() * max)
}
export function randomElement<T>(array: T[]): T {
  return array[randomIndex(array.length)]
}

const maxRetryCount = 5
const maxDelaySec = 30

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message
  } else if (typeof error == 'string') {
    return error
  } else {
    return String(error)
  }
}
export async function retry<T>(fn: () => Promise<T>, retries = 1): Promise<T> {
  try {
    return await fn()
  } catch (e) {
    const error = getErrorMessage(e)
    if (retries <= maxRetryCount) {
      const delay =
        Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000
      await new Promise((resolve) => setTimeout(resolve, delay))
      console.log(
        `Request failed, retrying ${retries}/${maxRetryCount}. Error ${error}`,
      )
      return retry(fn, retries + 1)
    } else {
      throw new Error(`Max retries exceeded. error: ${error}`)
    }
  }
}

export type TimerId = ReturnType<typeof setInterval>

export function useUnmount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fn, [])
}
export function useMount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, [])
}

export const appVersion = APP_VERSION

export function getHash(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}

export function sortBy<T>(arr: readonly T[], fn: (item: T) => number) {
  const newArr = [...arr]
  newArr.sort((a, b) => fn(a) - fn(b))
  return newArr
}

export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
): boolean {
  const [value, setValue] = useState(false)

  useEffect(() => {
    const element = elementRef.current
    if (!element) return

    const handleMouseEnter = () => {
      setValue(true)
    }
    const handleMouseLeave = () => {
      setValue(false)
    }

    element.addEventListener('mouseenter', handleMouseEnter)
    element.addEventListener('mouseleave', handleMouseLeave)
    document.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter)
      element.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [elementRef])

  return value
}

export function escapeRegExp(input: string) {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function range(end: number) {
  return Array.from({ length: end }, (_, i) => i)
}

export function getLast<T>(arr: readonly T[]): T | undefined {
  return arr[arr.length - 1]
}

export function groupBy<T, K extends keyof never>(
  arr: readonly T[],
  fn: (item: T) => K,
) {
  const result = {} as Record<K, T[]>
  for (const item of arr) {
    const key = fn(item)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const group = result[key] ?? []
    group.push(item)
    result[key] = group
  }
  return result
}

export function uniqueBy<T, K extends keyof never>(
  arr: readonly T[],
  fn: (item: T) => K,
) {
  const result = new Map<K, T>()
  for (const item of arr) {
    const key = fn(item)
    result.set(key, item)
  }
  return Array.from(result.values())
}

export function splitBy<T>(
  arr: readonly T[],
  predicate: (item: T) => boolean,
): [T[], T[]] {
  const result: [T[], T[]] = [[], []]
  for (const item of arr) {
    if (predicate(item)) {
      result[0].push(item)
    } else {
      result[1].push(item)
    }
  }
  return result
}

export function splitBySize<T>(arr: readonly T[], size: number): T[][] {
  const result: T[][] = []
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }
  return result
}

export function splitByCount<T>(arr: readonly T[], count: number): T[][] {
  const result: T[][] = []
  const chunkSize = Math.ceil(arr.length / count)
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize))
  }
  return result
}
export function shuffleArray<T>(arr: readonly T[]): T[] {
  const result = [...arr]
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = result[i]
    result[i] = result[j]
    result[j] = temp
  }
  return result
}

export function objectMap<T, R>(
  obj: Record<string, T>,
  fn: (item: T, key: string) => R,
) {
  const result = {} as Record<string, R>
  for (const key in obj) {
    result[key] = fn(obj[key], key)
  }
  return result
}
export function objectFilter<T>(
  obj: Record<string, T>,
  fn: (item: T, key: string) => boolean,
) {
  const result = {} as Record<string, T>
  for (const key in obj) {
    if (fn(obj[key], key)) {
      result[key] = obj[key]
    }
  }
  return result
}

export function debounce<T extends (...args: never[]) => void>(
  fn: T,
  delay: number,
): T {
  let timeout: ReturnType<typeof setTimeout>
  return ((...args: never[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }) as T
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function emptyFn() {}

export function deleteKey<T extends object, K extends keyof T>(
  obj: T,
  key: K,
): void {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete obj[key]
}
export function getValueByKey<T extends object, K extends keyof T>(
  obj: T,
  key: K | keyof never | undefined,
): T[K] | undefined {
  if (key === undefined) {
    return undefined
  }
  if (key in obj) {
    return obj[key as K]
  }
  return undefined
}

export function getValueByIndex<T>(
  arr: readonly T[] | undefined,
  index: number | undefined,
): T | undefined {
  if (
    index === undefined ||
    arr === undefined ||
    index < 0 ||
    index >= arr.length
  ) {
    return undefined
  }
  return arr[index]
}

export function arrayRotate(arr: number[], count: number) {
  const n = count % arr.length
  return arr.slice(n, arr.length).concat(arr.slice(0, n))
}
export function smoothArray(
  arr: number[],
  n: number,
  increasePercent: number,
): number[] {
  const result: number[] = []
  for (let i = 0; i < arr.length; i += n) {
    let sum = 0
    for (let j = i; j < i + n && j < arr.length; j++) {
      sum += arr[j]
    }
    const average = sum / n
    const rest = average > 0.2 ? 1 - average : 0
    result.push(average + rest * increasePercent)
  }
  return result
}
export function setIntervalWithRAF(callback: () => void, interval: number) {
  let lastTime = performance.now()
  let requestId: number

  const loop = (time: number) => {
    requestId = requestAnimationFrame(loop)
    if (time - lastTime >= interval) {
      lastTime = time
      callback()
    }
  }

  requestId = requestAnimationFrame(loop)

  return () => {
    cancelAnimationFrame(requestId)
  }
}

export function isMobileByUserAgent() {
  const userAgent = navigator.userAgent
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  )
}
export function startTimer() {
  const startTime = Date.now()
  return {
    stop: () => Date.now() - startTime,
  }
}

export function typedIncludes<T extends U, U>(
  coll: readonly T[],
  el: U,
): el is T {
  return coll.includes(el as T)
}

export function unzip<T, K>(arr: readonly [T, K][]): [T[], K[]] {
  const result: [T[], K[]] = [[], []]
  for (const [a, b] of arr) {
    result[0].push(a)
    result[1].push(b)
  }
  return result
}

export interface IMetrics {
  created: string
  updated: string
  name: string
  value: number
  order: number
}

export enum MetricKeysEnum {
  DaysSeries = 'days_series',
  DaysSeriesRecord = 'days_series_record',
  DaysLessons = 'days_lessons',
  DaysLessonsRecord = 'days_lessons_record',
  UniqueWords = 'unique_words',
  UniqueWordsChat = 'unique_words_chat_',
  TotalWords = 'total_words',
  ErrorsChat = 'errors_chat_',
  LessonsCompleted = 'lessons_completed',
}

export type IFilteredMetrics = Record<string, IMetrics[]>

export const filterMetrics = (
  metrics: IMetrics[],
  programTag?: string,
): IFilteredMetrics => {
  const newMetrics: IFilteredMetrics = {}

  metrics.forEach((metric: IMetrics) => {
    const pushMetric = (key: string, metric: IMetrics) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const metricArr = newMetrics[key] ?? []
      metricArr.push(metric)

      return metricArr
    }

    switch (metric.name) {
      case MetricKeysEnum.DaysSeries:
        return (newMetrics[MetricKeysEnum.DaysSeries] = pushMetric(
          MetricKeysEnum.DaysSeries,
          metric,
        ))
      case MetricKeysEnum.DaysSeriesRecord:
        return (newMetrics[MetricKeysEnum.DaysSeriesRecord] = pushMetric(
          MetricKeysEnum.DaysSeriesRecord,
          metric,
        ))
      case MetricKeysEnum.DaysLessons:
        return (newMetrics[MetricKeysEnum.DaysLessons] = pushMetric(
          MetricKeysEnum.DaysLessons,
          metric,
        ))
      case MetricKeysEnum.DaysLessonsRecord:
        return (newMetrics[MetricKeysEnum.DaysLessonsRecord] = pushMetric(
          MetricKeysEnum.DaysLessonsRecord,
          metric,
        ))
      case MetricKeysEnum.LessonsCompleted:
        return (newMetrics[MetricKeysEnum.LessonsCompleted] = pushMetric(
          MetricKeysEnum.LessonsCompleted,
          metric,
        ))
      case MetricKeysEnum.UniqueWords:
        return (newMetrics[MetricKeysEnum.UniqueWords] = pushMetric(
          MetricKeysEnum.UniqueWords,
          metric,
        ))
      case `${MetricKeysEnum.UniqueWordsChat}${programTag}`:
        return (newMetrics[`${MetricKeysEnum.UniqueWordsChat}${programTag}`] =
          pushMetric(`${MetricKeysEnum.UniqueWordsChat}${programTag}`, metric))
      case `${MetricKeysEnum.ErrorsChat}${programTag}`:
        return (newMetrics[`${MetricKeysEnum.ErrorsChat}${programTag}`] =
          pushMetric(`${MetricKeysEnum.ErrorsChat}${programTag}`, metric))
      default:
        return null
    }
  })
  return newMetrics
}

export function isKeyInObject<T extends object | null>(
  key: string,
  obj: T,
): boolean {
  if (obj === null) return false
  return Object.keys(obj).includes(key)
}

export interface Payload {
  item: string
  correct: boolean
}

export interface ICurrentAnswer {
  index: number
  isCorrect: boolean
}

export function getFTGPayload(
  answers: readonly string[],
  texts: readonly FillWordTextData[],
  userAnswers: ICurrentAnswer[],
) {
  return texts.reduce<Payload[]>((acc, cur, i) => {
    return [
      ...acc,
      {
        item: cur.vocabItem ?? answers[i],
        correct: userAnswers[i].isCorrect,
      },
    ]
  }, [])
}

export function validateEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email)
}

export function getProgressWordsMetricsDate(
  start: string,
  end: string,
  lang: AppLanguages,
  inBar = true,
) {
  const startDate = parseISO(start)
  const endDate = parseISO(end)
  const locale = lang === 'ru' ? ru : enUS

  const startDay = format(startDate, 'd', { locale })
  const endDay = format(endDate, 'd', { locale })
  const startMonth = format(startDate, 'MMM', { locale })
  const endMonth = format(endDate, 'MMM', { locale })
  const formattedStartMonth = getPreparedString(startMonth)
  const formattedEndMonth = getPreparedString(endMonth)

  return inBar
    ? `${formattedStartMonth.replace('.', '')} ${startDay}-${endDay}`
    : `${startDay} ${formattedStartMonth} — ${endDay} ${formattedEndMonth}`
}

export function getPreparedString(word: string) {
  const prepWord = word.charAt(0).toUpperCase() + word.slice(1)
  return prepWord.replace('.', '')
}

const GOAL_SUBSCRIPTION_MAP = {
  'Найти идеальную работу в компании мечты': 'work',
  'Чувствовать себя комфортно в иностранной среде': 'goAbroad',
  'Путешествовать без переводчика в кармане': 'travel',
  'Говорить на живом языке, а не текстами из школьного учебника':
    'communication',
  'Смотреть фильмы и читать книги в оригинале': 'forYourself',
}

export const getDescId = (desc: string, goal?: string) => {
  return goal
    ? `${desc}${
        GOAL_SUBSCRIPTION_MAP[goal as keyof typeof GOAL_SUBSCRIPTION_MAP]
      }`
    : desc
}

export const getGenerationAnimDelay = (
  variant: 'first' | 'second',
  index?: number,
) => {
  if (variant === 'first' && index) {
    return {
      duration: 0.3,
      delay: index * 2 + 0.25,
    }
  }
  if (variant === 'second') {
    return { duration: 0.3, delay: 1.2 }
  }
}
